import { DefaultTheme } from "styled-components";
import logo from "../../../assets/images/OrlyMbj.png";

export const orlyTheme: DefaultTheme = {
  //colors :
  //write in hexadecimal ! :

  primary: "#2b4ed9",
  primaryDarken: "#2b4ed9",
  primaryLighten: "#4a6aed",
  primaryPastel: "#d0f1ff",

  secondary: "#4C9CD7",
  secondaryDarken: "#277ca8",
  secondaryLighten: "#74b6ec",
  secondaryPastel: "#a6d5ff",

  third: "#74B95F",
  thirdDarken: "#519a3e",
  thirdLighten: "#86c475",
  thirdPastel: "#b4eca4",

  complementary: "#C569B4",
  complementaryDarken: "#ad489d",
  complementaryLighten: "#ce80bd",
  complementaryPastel: "#fcb5e9",

  grey: "#868686",
  greyDarken: "#616161",
  greyLighten: "#B5B5B5",
  greyPastel: "#E6E6E6",

  warning: "#BC2067",
  warningDarken: "#9d0e51",
  warningLighten: "#d54387",
  warningPastel: "#f87aa1",

  dark: "#2A2245",
  lightText: "#8E8E8E",
  background1: "#ECF0F5",
  background2: "#FFFFFF",

  //font size :
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
  },

  logo: logo,
};
