import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface MbjSquareChartCtrlProps {
  className?: string;
  number: number;
  total: number;
  legend: string;
  color?: string;
}

const MbjSquareChartCtrl = (props: MbjSquareChartCtrlProps) => {
  return (
    <div className={`oneRepart ${props.className}`}>
      <div className="bloc-rising">
        <div className="bloc-in-rising" />
      </div>
      <div className="legende">
        <span className="nbIn me-2">{props.number}</span>
        <span>{props.legend}</span>
      </div>
    </div>
  );
};
const Progress = () => keyframes`
    from {
        height: 0%;
    }

`;
export const MbjSquareChart = styled(MbjSquareChartCtrl)(
  (props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    '.nbIn': {
      fontSize: '18px',
      fontWeight: 'bold',
      marginRight:"8px"
    },
    '.bloc-rising': {
      height: '120px',
      border: 'solid 1px #ccc',
      position: 'relative',
      borderTopLeftRadius:"6px",
      borderTopRightRadius:"6px",
      '.bloc-in-rising': {
        width: '100%',
        position: 'absolute',
        bottom: '0',
        left: '0',
        height: (props.number / props.total) * 100 + '%',
        backgroundColor: props.color,
      },
    },
    '.legende': {
      width: '100%',
      padding: '0.25rem',
      textAlign: 'center',

      '.nbIn': {
        color: props.color,
      },
    },
  }),
  css`
    .bloc-in-rising {
      animation: ${(props: MbjSquareChartCtrlProps) => Progress} 1.5s ease-out;
    }
  `
);
MbjSquareChart.defaultProps = {
  color: 'green',
};
