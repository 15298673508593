import * as React from "react";
import styled, { useTheme } from "styled-components";
import {Link, useLocation} from "react-router-dom";
import { logoMBJ, MbjAvatar } from "@mbj-front-monorepo/ui";
import { RegistrationForm } from "./RegistrationForm/RegistrationForm";

interface RegistrationCtrlProps {
  className?: string;
}

function RegistrationCtrl(props: RegistrationCtrlProps) {
  const theme = useTheme();

  const location = useLocation();

  return (
    <div className={"Login " + props.className}>
      <div className="imageBack">
        <img src={theme.logo} alt="logo mbj" />
      </div>
      <div className="connexion-form-container">
        <MbjAvatar className="MbjBadge" src={logoMBJ} objectFit={"contain"} bg={"#ffffff"} withShadow padding={"6px"} />

        <RegistrationForm location={location} />
        <Link style={{margin:"15px auto"}} to={'/login'}>Retour à la connexion</Link>
      </div>
    </div>
  );
}
export const Registration = styled(RegistrationCtrl)((props) => ({
  backgroundColor: props.theme.background1,
  minHeight: "100vh",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& .imageBack": {
    position: "fixed",
    width: "60vw",
    height: "100%",
    backgroundColor: props.theme.dark,
    top: 0,
    left: 0,
    boxShadow: "6px 0px 11px #00000040",
    img: {
      position: "absolute",
      left: "10px",
      top: "10px",
      maxWidth: "257px",
      width: "80%",
      zIndex: 1,
    },
  },

  "& .connexion-form-container": {
    position: "relative",
    display: "flex",
    borderTop: "30px solid " + props.theme.primaryPastel,
    flexDirection: "column",
    textAlign: "center",
    width: "30%",
    padding: "30px",
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    //height: "450px",
    minWidth: "fit-content",
    zIndex: 2,
    boxShadow: "0px 3px 6px #00000029",

    ".MbjBadge": {
      position: "absolute",
      top: "-20px",
      right: "20px",
    },

    form: {
      paddingTop: "34px",
    },
  },
}));
