import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { fetchApi, Operation } from "../../services/fetchApi";
import {toast} from "react-toastify";
import {useMe} from "@mbj-front-monorepo/data-access";

export const useGetAllNotifications = ()=>{
  const promise = () => fetchApi("/api/notifications", { method: "get"});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["notifications"], promise);
}

export const useReadNotification = (idNotif:number)=>{
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"putNotificationsItem", "requestBody">) =>
      fetchApi("/api/notifications/{id}", { method: "put", params: { id: idNotif + "" }, json: data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
