import React, {useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjFlex,
  MbjIconButton,
  MbjListItem,
  MbjLoading,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { useGetParticulierMe, useGetPartMob } from "@mbj-front-monorepo/data-access";
import { MbjMobiliteListItem } from "@mbj-front-monorepo/data-ui";
import { FiRefreshCw } from "react-icons/fi";
import { getDateTimeFR } from "@mbj-front-monorepo/utils";

interface MobilitesByMbjCardCtrlProps extends MbjCardClassicCtrlProps {}

const limit = 0.8;

interface TrancheSalaire{
  id:number;
  libelle:string;
  borneInf:number;
  borneSup:number;
}

const Tranches:TrancheSalaire[]=[
  {id:0, borneInf:0, borneSup:9999999, libelle:'Tous les salaires'},
  {id:1, borneInf:0, borneSup:10000, libelle:'0 - 10K€'},
  {id:2, borneInf:10001, borneSup:40000, libelle:'10K€ - 40K€'},
  {id:3, borneInf:40001, borneSup:80000, libelle:'40K€ - 80K€'},
  {id:4, borneInf:80001, borneSup:9999999, libelle:'80K€ et plus'},
]

const MobilitesByMbjCardCtrl = (props: MobilitesByMbjCardCtrlProps) => {
  const particulierQuery = useGetParticulierMe();
  const refTranche = useRef<HTMLSelectElement>(null)
  const mobilitesQuery = useGetPartMob(particulierQuery.data?.id);
  const [idTranche, setIdTranche] = useState<number>(0)
  const mobilitesFiltered = useMemo(() => {
    if (mobilitesQuery.isError || mobilitesQuery.isLoading || !mobilitesQuery.data) {
      return [];
    } else {
      const MyTranche = Tranches.find(t=>t.id === idTranche);
      return mobilitesQuery.data.filter((m) => {
        const CondLimit = m.score && m.score > limit;
        let CondTranche = true;
        if(MyTranche && m.Metier?.salaire){
          CondTranche = (m.Metier?.salaire>=MyTranche.borneInf && m.Metier?.salaire<=MyTranche.borneSup) || m.Metier?.salaire === -1;
        }
        return CondLimit && CondTranche;
      });
    }
  }, [mobilitesQuery.data, mobilitesQuery.isLoading, mobilitesQuery.isError, limit, idTranche]);
  const HandleChangeTranche = ()=>{
    if(refTranche.current){
      setIdTranche(parseInt(refTranche.current.value));
    }
  }
  return (
    <MbjCardClassic title="Mobilités les plus favorables selon My-Better-Job" noPadding {...props}>
      <MbjFlex direction={"column"} height={"100%"} gap={"0px"}>
        <MbjFlex className={"top"} justify={"space-between"} align={"center"}>
          <span>
            {particulierQuery.data?.lastMobiliteCalc
              ? `Dernière mise à jour le ${getDateTimeFR(new Date(particulierQuery.data?.lastMobiliteCalc))}`
              : "Jamais calculé"}
          </span>
          <MbjIconButton
            size={"sm"}
            onClick={(e) => console.log("refresh")}
            icon={<FiRefreshCw />}
            ariaLabel={"Raffraichir les mobilités"}
          />
        </MbjFlex>
        <div className={`filtre_tranche`}>
          <label>Filtre sur les salaires</label>
          <select ref={refTranche} value={idTranche} onChange={HandleChangeTranche}>
            {Tranches.map((t:TrancheSalaire)=>(
              <option value={t.id} key={`one_tranche${t.id}`}>{t.libelle}</option>
            ))}
          </select>
        </div>
        <MbjScrollArea grow={1}>
          {mobilitesQuery.isLoading ? (
            <MbjLoading />
          ) : mobilitesQuery.isError ? (
            "Errors"
          ) : (
            mobilitesFiltered
              .sort(function (a, b) {
                return (b.score || 0) - (a.score || 0);
              })
              .map((mobilite, idx) => (
                <MbjListItem key={mobilite.id} to={`${mobilite.id}`}>
                  <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
                </MbjListItem>
              ))
          )}
        </MbjScrollArea>
      </MbjFlex>
    </MbjCardClassic>
  );
};

export const MobilitesByMbjCard = styled(MobilitesByMbjCardCtrl)((props) => ({
  ".top": {
    padding: "5px",
    borderBottom: `1px solid ${props.theme.primaryLighten}`,
  },
  ".filtre_tranche":{
    width:"100%",
    padding:"0.5rem 0.25rem",
    borderBottom: `1px solid ${props.theme.primaryLighten}`,
    "select":{
      marginLeft:"10px",
      height:"38px",
      borderRadius:"8px",
      border:`1px solid ${props.theme.primaryLighten}`,
    }
  }
}));
