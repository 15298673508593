import * as React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MbjHeading } from "../../atoms/MbjHeading/MbjHeading";
import { MbjIconButton } from "../../atoms/MbjButton/MbjIconButton";

export interface MbjModalCtrlProps {
  /**
   * Classe HTML de la card?
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance du container sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Titre du container ?
   */
  title?: string;
  /**
   * Contenu de la container ?
   */
  children?: React.ReactNode;
  /**
   * La modale est elle ouverte ou non ?
   */
  isShowing: boolean;
  /**
   * Fonction permettant de rendre visible ou non la modale
   */
  hide: () => void;
  /**
   * Largeur max :
   */
  maxWidth?: string;
  /**
   *
   */
  isSubRoute?: boolean;

  fitContent?: boolean;
  closable?: boolean;
}

function MbjModalCtrl(props: MbjModalCtrlProps) {
  const navigate = useNavigate();
  if (!props.isShowing && !props.isSubRoute) {
    return <React.Fragment />;
  }

  return ReactDOM.createPortal(
    <div className={props.className}>
      <div className="modal-wrapper">
        <div className="modal-x">
          <div className="modal-header">
            {props.title ? (
              // eslint-disable-next-line react/jsx-no-undef
              <MbjHeading themeColor={props.themeColor} className="titleContainer">
                {props.title}
              </MbjHeading>
            ) : (
              <React.Fragment />
            )}
            {(props.closable === undefined || props.closable === true) && (
              <button className={`close_me`} type={"button"} onClick={!props.isSubRoute ? props.hide : () => navigate(-1)}>
                <IoClose />
              </button>

            )}
          </div>
          <div className="modalBody">{props.children}</div>
        </div>
      </div>
    </div>,
    document.body
  );
}

export const MbjModal = styled(MbjModalCtrl)((props) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000,
  backgroundColor: "rgba(110,110,110,0.5)",
  height: "100vh",
  width: "100vw",
  ".close_me":{
    background:"transparent",
    border:"none",
    fontSize:"20px",
    "&:hover":{
      cursor:"pointer"
    }
  },
  ".modal-wrapper": {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1050,
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    outline: 0,
    display: "flex",
    alignItems: "center",

    ".modal-x": {
      zIndex: 100,
      background: "white",
      position: "relative",
      margin: "auto",
      borderRadius: "5px",
      maxWidth: props.maxWidth,
      width: props.fitContent ? "80%" : "unset",
      minWidth: props.fitContent ? "fit-content" : "unset",
      padding: "1rem",

      ".modal-header": {
        all: "unset",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #dee2e6",
        paddingBottom: "0.8rem",
      },
      ".modalBody": {
        padding: "0.5rem 0.5rem 0 0.5rem",
      },
    },
  },

  "& .titleContainer": {
    lineHeight: "1em",
  },
  "& > .modalContent": {},
}));
MbjModal.defaultProps = {
  themeColor: "primary",
  maxWidth: "500px",
  isSubRoute: false,
  fitContent: false,
};
