import React from "react";
import styled from "styled-components";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjScrollArea } from "@mbj-front-monorepo/ui";
import { Operation } from "@mbj-front-monorepo/data-access";
import { MbjCompareActiviteItem } from "../../atoms/MbjCompareActiviteItem/MbjCompareActiviteItem";

const TabRef = [
  { libelle: "shared", color: "#74B95F" },
  { libelle: "toRise", color: "#F69336" },
  { libelle: "missed", color: "#D30320" },
];

interface MbjCompareActiviteCardProps extends MbjCardClassicCtrlProps {
  List?: Operation<"getProximiteReferentielItem">["Shared" | "Missed" | "ToRised"];
  type: string;
}

const MbjCompareActiviteCardCtrl = (props: MbjCompareActiviteCardProps) => {
  const Ref = TabRef.filter((item) => item.libelle === props.type);
  return (
    <MbjCardClassic colorBack={Ref[0].color} colorFront={"white"} {...props}>
      <MbjScrollArea maxHeight={"450px"}>
        {props.List?.map((item, idx: number) => (
          <MbjCompareActiviteItem bilanItem={item} color={Ref[0].color} key={idx} />
        ))}
      </MbjScrollArea>
    </MbjCardClassic>
  );
};

export const MbjCompareActiviteCard = styled(MbjCompareActiviteCardCtrl)((props) => ({}));
