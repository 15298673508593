
import {useQuery} from "@tanstack/react-query";

import { useGetMemberMe } from "../member";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useGetAllSearchByMember = ()=>{
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/searchs", { method: "get", query: { Member: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["search", { memberId }], promise, {
    enabled: !!memberId,
  });
}

export const useGetAllSearchByUM = (idUM:number|null)=>{
  const promise = () => fetchApi("/api/searchs", { method: "get", query: { UserMetier: (idUM||-1) + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["search_um", { idUM }], promise, {
    enabled: !!idUM,
  });
}
