import * as React from "react";
import {
  MbjCardClassic,
  MbjCirclePercent,
  MbjFlex,
  MbjLoading,
  MbjOpenCloseDetails,
  MbjSquareChart,
} from "@mbj-front-monorepo/ui";

import styled from "styled-components";
import { ReferentielsComparaisonType, useGetProximiteReferentiel } from "@mbj-front-monorepo/data-access";
import { MbjCompareActiviteCard } from "../../molecules/MbjCompareActiviteCard/MbjCompareActiviteCard";

interface MbjCompareReferentielCtrlProps {
  className?: string;

  title?: string;

  idToCompare1: number;
  idToCompare2: number;
  typeCompared: ReferentielsComparaisonType;
}

const MbjCompareReferentielCtrl = (props: MbjCompareReferentielCtrlProps) => {
  const proximiteReferentielQuery = useGetProximiteReferentiel(
    props.typeCompared,
    props.idToCompare1,
    props.idToCompare2
  );

  const totalComp: number =
    (proximiteReferentielQuery.data?.Shared?.length || 0) +
    (proximiteReferentielQuery.data?.Missed?.length || 0) +
    (proximiteReferentielQuery.data?.ToRised?.length || 0);

  return (
    <MbjFlex direction={"column"} className={props.className} gap={"10px"}>
      {proximiteReferentielQuery.isLoading ? (
        <MbjLoading />
      ) : proximiteReferentielQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjFlex>
            <MbjCardClassic title="Proximité du référentiel" basis={"25%"}>
              <div style={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                <MbjCirclePercent
                  percent={
                    proximiteReferentielQuery.data?.score ? Math.round(proximiteReferentielQuery.data?.score * 100) : 0
                  }
                />
              </div>
            </MbjCardClassic>
            <MbjCardClassic title="Répartition des compétences" basis={"70%"}>
              <div className="chartsContainer">
                <MbjSquareChart
                  number={proximiteReferentielQuery.data?.Shared?.length || 0}
                  total={totalComp}
                  legend={"compétences partagées"}
                  color={"#74B95F"}
                />

                <MbjSquareChart
                  number={proximiteReferentielQuery.data?.ToRised?.length || 0}
                  total={totalComp}
                  legend={"compétences à améliorer"}
                  color={"#F69336"}
                />
                <MbjSquareChart
                  number={proximiteReferentielQuery.data?.Missed?.length || 0}
                  total={totalComp}
                  legend={"compétences à acquérir"}
                  color={"#D30320"}
                />
              </div>
            </MbjCardClassic>
          </MbjFlex>

          <MbjOpenCloseDetails className={"detailToggle"} text={"le detail"} themeColor="primary">
            <MbjFlex gap={"13px"}>
              <MbjCompareActiviteCard
                type="shared"
                List={proximiteReferentielQuery.data?.Shared}
                title="Compétences partagées"
                basis={"31%"}
              />
              <MbjCompareActiviteCard
                type="toRise"
                List={proximiteReferentielQuery.data?.ToRised}
                title="Compétences à développer"
                basis={"31%"}
              />
              <MbjCompareActiviteCard
                type="missed"
                List={proximiteReferentielQuery.data?.Missed}
                title="Compétences à acquérir"
                basis={"31%"}
              />
            </MbjFlex>
          </MbjOpenCloseDetails>
        </>
      )}
    </MbjFlex>
  );
};

export const MbjCompareReferentiel = styled(MbjCompareReferentielCtrl)((props) => ({
  ".chartsContainer": {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    padding: "9px 9px",
    flexWrap: "wrap",

    "& > *": {
      flexGrow: 1,
    },
  },
  ".detailToggle": {
    marginLeft: "auto",
  },
}));
