import {
  MbjButton,
  MbjEmailSimpleInput,
  MbjFlex,
  MbjPasswordSimpleInput,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Operation, useRegisterParticulierOrly } from "@mbj-front-monorepo/data-access";
import {useState} from "react";

interface RegistrationFormCtrlProps {
  className?: string;

  location: any;
}

interface ErrorTab{
  code:number;
  libelle:string;
}
const TabError:ErrorTab[] = [
  {code:410, libelle:'Ce username existe déjà'},
  {code:411, libelle:'Cet email existe déjà'},
  {code:412, libelle:'Les mots de passes ne sont pas identique'},
]

function RegistrationFormCtrl(props: RegistrationFormCtrlProps) {
  const mutationAddParticulier = useRegisterParticulierOrly();
  const [registrationEnd, setRegistrationEnd] = useState(false);
  const [error, setError] = useState<string>('')
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<Operation<"addOrlyUserCollection", "requestBody">>({
    mode: "onChange",
  });

  const password = watch("password", "");
  const handleSuby = (data:any)=>{
    mutationAddParticulier.mutate(data, {
      onSuccess:()=>{
        setRegistrationEnd(true);
      }, onError:(e)=>{
        const MyError = TabError.find(t=>t.code === parseInt(e.message));
        setError(MyError ? MyError.libelle : 'une erreur est survenue, merci de retenter plus tard');
      }
    });
  }
  return (
    <>
    {registrationEnd ?
        <p style={{fontSize:"18px"}}>Votre enregistrement est terminé, d'ici quelques minutes vous recevrez un email de confirmation. Pensez à vérifier vos spams</p>:

      <form
        onSubmit={handleSubmit(handleSuby)}
        className={"ConnexionFormLogin" + props.className}
      >
        <h1>Inscription</h1>
        <div>Entrez-vos informations ci-dessous</div>
        <MbjFlex>
          <MbjSimpleInputContainer errors={errors} label={"Nom *"}>
            <MbjSimpleInput
              name={"nom"}
              id={"nom"}
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Nom  requis",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={errors} label={"Prénom *"}>
            <MbjSimpleInput
              name={"prenom"}
              id={"prenom"}
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Prénom requis",
                },
              }}
            />
          </MbjSimpleInputContainer>
        </MbjFlex>
        <MbjFlex>
          <MbjSimpleInputContainer errors={errors} label={"Nom d'utilisateur *"}>
            <MbjSimpleInput
              name={"username"}
              id={"username"}
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Nom d'utilisateur requis",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={errors} label={"Adresse mail *"}>
            <MbjEmailSimpleInput
              name={"mail"}
              id={"mail"}
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Adresse mail requise",
                },
              }}
            />
          </MbjSimpleInputContainer>
        </MbjFlex>
        <MbjFlex>
          <MbjSimpleInputContainer errors={errors} label={"Code postal *"}>
            <MbjSimpleInput
              name={"codepostal"}
              id={"codepostal"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Code postal requis",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={errors} label={"Ville *"}>
            <MbjSimpleInput
              name={"ville"}
              id={"ville"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Ville requise",
                },
              }}
            />
          </MbjSimpleInputContainer>
        </MbjFlex>
        <MbjSimpleInputContainer errors={errors} label={"Mot de passe *"}>
          <MbjPasswordSimpleInput name={"password"} id={"password"} register={register} />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={errors} label={"Confirmation du mot de passe *"}>
          <MbjPasswordSimpleInput
            name={"confirmPassword"}
            id={"confirmPassword"}
            register={register}
            registerOptions={{
              validate: (value) => value === password || "Les mots de passes ne correspondent pas",
            }}
          />
        </MbjSimpleInputContainer>
        {error!=='' &&
          <p className={"error"}>{error}</p>
        }
        <MbjButton themeColor="primary" isPending={mutationAddParticulier.isLoading}>
          Inscription
        </MbjButton>
        {/*<MbjLink to="/forgot-password">Mot de passe oublié ?</MbjLink>*/}
      </form>
    }
    </>

  );
}

export const RegistrationForm = styled(RegistrationFormCtrl)((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  "p.error":{
      fontSize : "16px",
      color:"red"
  },
  ".rowContainer": {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },

  "& .incorrect": {
    color: "#bf1650",
    fontSize: "16px",
  },
}));
