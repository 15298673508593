import React from "react";
import styled from "styled-components";

interface MbjHeadingCtrlProps {
  /**
   * Classe HTML du conteneur du titre
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance du bouton sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary" | "dark";
  /**
   * Texte du titre :
   */
  text?: string;
  children?: React.ReactNode;
  /**
   * Niveau du titre :
   */
  level?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  /**
   * Ligne de decoration ?
   */
  withLine?: boolean;
  marginIn?: string;
  colorFront?:string;
}

function MbjHeadingCtrl(props: MbjHeadingCtrlProps) {
  const Component = props.level || "h2";
  // if (props.level === "h1") {
  //   return (
  //     <div className={props.className}>
  //       <h1>{props.children || props.text}</h1>
  //       {props.withLine ? <div className="line" /> : <React.Fragment />}
  //     </div>
  //   );
  // } else if (props.level === "h2") {
  //   return (
  //     <div className={props.className}>
  //       <h2>{props.children || props.text}</h2>
  //     </div>
  //   );
  // }
  // return <></>;

  return <Component className={props.className}>{props.children || props.text}</Component>;
}
/**
 * Titre
 */
export const MbjHeading = styled(MbjHeadingCtrl)((props) => ({
  display: "flex",
  alignItems: "center",
  height: "fit-content",
  color:props.colorFront ? props.colorFront : props.theme.primaryDarken,
  fontSize:
    props.size === "xs"
      ? "1rem"
      : props.size === "sm"
      ? "1.1rem"
      : props.size === "md"
      ? "1.25rem"
      : props.size === "lg"
      ? "1.35rem"
      : props.size === "xl"
      ? "1.5rem"
      : props.size === "2xl"
      ? "1.75rem"
      : "1.25rem",

  // color:
  //   props.level === "h1"
  //     ? props.theme.dark
  //     : props.level === "h2"
  //     ? props.themeColor === "primary"
  //       ? props.theme.primary
  //       : props.themeColor === "secondary"
  //       ? props.theme.secondary
  //       : props.themeColor === "third"
  //       ? props.theme.third
  //       : props.themeColor === "complementary"
  //       ? props.theme.complementary
  //       : props.themeColor === "dark"
  //       ? props.theme.dark
  //       : "none"
  //     : "none",
  "& .line": {
    flexGrow: 1,
    height: "2.5px",
    backgroundColor: props.theme.dark,
    marginLeft: "1em",
    marginTop: "0.2em",
  },
  h1: {
    all: "unset",
    fontSize: "calc(12px + 0.9vmin)",
    fontWeight: 500,
    //marginTop:"20px",
    //marginBottom:"10px",
  },
  h2: {
    fontSize: "16px",
    marginBottom: props.marginIn ? props.marginIn : "auto",
  },
  h3: {
    fontSize: "16px",
  },
}));
MbjHeading.defaultProps = {
  themeColor: "primary",
  withLine: true,
  level: "h2",
  size: "sm",
  marginIn: "12px"
};
