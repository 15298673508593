import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi } from "../../services/fetchApi";

export const useGetMemberMe = () => {
  const promise = () => fetchApi("/api/members/me", "get");
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member", "me"], promise);
};

export const useGetMember = (memberId: number|undefined) => {
  const promise = () => fetchApi("/api/members/{id}", { method: "get", params: { id: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetAllMember = () => {
  const promise = () => fetchApi("/api/members", { method: "get" });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["members"], promise);
};

export const useMutateMember = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();

  return useMutation(
    (data: any) => {
      return fetchApi("/api/members/{id}", { method: "put", params: { id: memberQuery.data?.id + "" }, json: data });
    },
    {
      onSuccess: (newData) => {
        queryClient.setQueryData(["member", "me"], newData);
        toast.success("Profil modifié !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetCompareRecl = (idR: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/user_metier_proximite_reclassements", {
      method: "get",
      query: { Member: memberId, Reclassement: idR },
    });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["compare_metier_recl", idR], promise, {
    enabled: !!memberId,
  });
};

export const useGetMemberAlliances = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/members/{id}/member_alliances", { method: "get", params: { id: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member", memberId, "alliances"], promise, {
    enabled: !!memberId,
  });
};

export const useGetAllianceInvitations = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/alliance_invitations", { method: "get", query: { MemberTo: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["alliance_invitation", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetBesoinsFormationEntreprise = ()=>{
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/besoin_formation_entreprises", { method: "get", query: { idMembre: memberId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["besoin_formation", { memberId }], promise, {
    enabled: !!memberId,
  });
}

/*
export const useChangeLogo = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();

  return useMutation(
    (file: File) => {
      return changeLogo(file, memberQuery.data?.id);
    },
    {
      onSuccess: (newData) => {
        queryClient.setQueryData(["me"], newData);
        toast.success("Image de profil modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
*/
